import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { rhythm } from "../../utils/typography"
import Container from "../../components/Container"
import MasterLayout from "../../components/MasterLayout"

export const pageQuery = graphql`
  query ArticlesBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`

const TitleWraper = styled.h1`
  color: inherit;
`

const PostDateWrapper = styled.p`
  display: block;
  margin-bottom: ${rhythm(1)};
  margin-top: ${rhythm(-0.5)};
  font-size: smaller;
`

const HtmlWrapper = ({ html }) => (
  <div dangerouslySetInnerHTML={{ __html: html }} />
)

const ArticlesTemplate = ({ data, pageContext }) => {
  const post = data.markdownRemark

  return (
    <MasterLayout>
      <Container>
        <Helmet title={post.frontmatter.title} defer={false} />
        <TitleWraper>{post.frontmatter.title}</TitleWraper>
        <PostDateWrapper>{post.frontmatter.date}</PostDateWrapper>
        <HtmlWrapper html={post.html} />
      </Container>
    </MasterLayout>
  )
}

export default ArticlesTemplate
